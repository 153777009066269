/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 * @flow
 */
import React, { Component } from "react";
import {
  FloatingLabelTextField,
  AZSecondaryButton,
  LinkText
} from "@az/az-web-components";
import { parseUrl } from "query-string";
import b2blogo from "./images/AZ_Pro_MobileAppLogo.png";
import diylogo from "./images/auto_zone_logo.svg";
import closedEyeIcon from "./images/close_eye_icon.svg";
import openEyeIcon from "./images/open_eye_icon.svg";
import "./App.css";

/**
 * This is the main login component.
 */
type State = {
  username: string,
  password: string,
  showInvalidCredential: boolean,
  showRequiredField: boolean,
  showInactive: boolean,
  showInternalError: boolean,
  usernameError: boolean,
  passwordError: boolean,
  passwordValid: boolean,
  passwordInfo: string,
  scope: string,
  responseType: string,
  clientId: string,
  codeChallenge: string,
  codeChallengeMethod: string,
  redirectUri: string,
  url: string,
  state: string,
  autoLogin: string,
  isShowPassword: boolean,
  eyeIconAltStr: string
};
type Props = {
  /* ... */
};
class App extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.formRef = React.createRef();
    const URI = parseUrl(window.location.href);
    this.state = {
      username: "",
      password: "",
      showInvalidCredential: false,
      showRequiredField: false,
      showInactive: false,
      showInternalError: false,
      usernameError: false,
      passwordError: false,
      passwordValid: false,
      passwordInfo: "",
      scope: URI.query.scope,
      responseType: URI.query.response_type,
      clientId: URI.query.client_id,
      codeChallenge: URI.query.code_challenge,
      codeChallengeMethod: URI.query.code_challenge_method,
      redirectUri: URI.query.redirect_uri,
      state: URI.query.state,
      autoLogin: URI.query.autoLogin,
      url: window.env.pkceLoginURL,
      isShowPassword: false,
      eyeIconAltStr: "Show Password"
    };

    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.validateUsername = this.validateUsername.bind(this);
    this.usernameInformation = this.usernameInformation.bind(this);
    this.passwordInformation = this.passwordInformation.bind(this);
    this.handlePasswordToggle = this.handlePasswordToggle.bind(this);

    if (URI.query.pkceLoginURL) {
      this.state.url = URI.query.pkceLoginURL;
    }

    // In some instances, the service may add multiple "error" URL params, which our library parses as an array. We therefore need to handle both when we have the single value and when we have an array. Since we only display one error message at a time, prefer to show whatever the last error is. This code handles checking the last item in the array.
    if (
      URI.query.error === "Unauthorized" ||
      (URI.query.error instanceof Array &&
        URI.query.error[URI.query.error.length - 1] === "Unauthorized")
    ) {
      this.state.showInvalidCredential = true;
    } else if (URI.query.error !== undefined) {
      this.state.showInternalError = true;
    }
    // TODO: Need to understand what "error" will respond with when the user is inactive still
    // } else if (URI.query.error === "true") {
    //   this.state.showInactive = true;
    // }
  }

  formRef: { current: null | HTMLFormElement };

  handleUsernameChange: (SyntheticInputEvent<*>) => void;

  handleUsernameChange(event: SyntheticInputEvent<*>): void {
    if (this.state.scope === "diycustomers") {
      if (event.target.value.length <= 40)
        this.setState({ username: event.target.value });
    } else {
      this.setState({ username: event.target.value });
    }
  }

  validateUsername: string => string;

  validateUsername(value: string) {
    return value.match(/^[a-zA-Z0-9-]+$/) ||
      value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
      ? ""
      : "Please use a valid username or email address";
  }

  usernameInformation: string => string;

  usernameInformation(value: string) {
    return value.length <= 39
      ? ""
      : "Username must be less than or equal to 40 characters";
  }

  passwordInformation: string => string;

  passwordInformation(value: string) {
    let passwordValid = this.state.passwordValid;
    let passwordInfo = this.state.passwordInfo;
    passwordValid = value.length >= 4;
    passwordInfo = passwordValid
      ? ""
      : "Password must be at least 4 characters";
    if (passwordValid) {
      passwordValid = value.length <= 29;
      passwordInfo = passwordValid
        ? ""
        : "Password must be less than or equal to 30 characters";
    }
    return passwordInfo;
  }

  handlePasswordChange: (SyntheticInputEvent<*>) => void;

  handlePasswordChange(event: SyntheticInputEvent<*>): void {
    if (this.state.scope === "diycustomers") {
      if (event.target.value.length <= 30)
        this.setState({ password: event.target.value });
    } else {
      this.setState({ password: event.target.value });
    }
  }

  handlePasswordToggle = () => {
    this.setState(prevState => ({
      isShowPassword: !prevState.isShowPassword,
      eyeIconAltStr: prevState.isShowPassword
        ? "Show Password"
        : "Hide Password"
    }));
  };

  handleSubmit: Event => void;

  handleSubmit(event: Event): void {
    window.localStorage.setItem("shouldRedirect", "true");
    this.setState({ showInvalidCredential: false, showInactive: false });
    this.setState(state => ({
      usernameError: state.username.length === 0,
      passwordError: state.password.length === 0
    }));
    if (this.state.username.length === 0 || this.state.password.length === 0) {
      this.setState({ showRequiredField: true });
      event.preventDefault();
    } else {
      this.setState({ showRequiredField: false });
    }
  }

  handleKeyPress: (string, Event & { key: string }) => void;

  handleKeyPress(value: string, event: Event & { key: string }): void {
    if (event.key === "Enter") {
      const loginForm = document.getElementById("loginForm");
      if (loginForm != null) {
        const passwordField = document.getElementsByName("password")[0];
        if (value === "password") {
          passwordField.focus();
          event.preventDefault();
        }
      }
    }
  }

  componentDidMount() {
    if (this.state.scope === "az_mobile_sso") {
      document.title = "AutoZoner Login";
      if (this.state.autoLogin === undefined) {
        window.localStorage.removeItem("shouldRedirect");
        this.formRef && this.formRef.current && this.formRef.current.submit();
      }
      if (this.state.redirectUri.includes("http") === false) {
        window.addEventListener("blur", () => {
          window.close();
        });
      }
      if (this.state.redirectUri.includes("http") === true) {
        window.addEventListener("focus", () => {
          if (window.localStorage.getItem("shouldRedirect") !== undefined) {
            localStorage.removeItem("shouldRedirect");
            window.location.href = this.state.redirectUri;
          }
        });
        window.addEventListener("blur", () => {
          window.addEventListener("focus", () => {
            window.location.href = window.location.href
              .replace("&autoLogin=false", "")
              .replace("&error=Unauthorized", "");
          });
        });
      }
    } else {
      document.title = "AutoZonePro Login";
    }
  }

  render() {
    const scope = this.state.scope;
    return (
      // <form
      //   onSubmit={this.handleSubmit}
      //   action={this.state.url}
      //   method="post"
      //   id="loginForm"
      // >
      <div className={scope === "diycustomers" ? "diy-app" : "b2b-app"}>
        {scope === "diycustomers" ? (
          <img className="diy-logo" src={diylogo} alt="az-logo" />
        ) : (
          <img src={b2blogo} alt="az-logo" />
        )}
        {scope === "diycustomers" ? null : (
          <>
            {scope === "az_mobile_sso" ? (
              <h1>AutoZoner Login</h1>
            ) : (
              <h1>AutoZonePro Login</h1>
            )}
          </>
        )}

        {this.state.showInvalidCredential && (
          <p className="error">
            We are unable to find your username or password, please try again.
          </p>
        )}
        {this.state.showRequiredField && (
          <p className="error">This field is required.</p>
        )}
        {this.state.showInactive && (
          <p className="error">
            Your account is inactive. Please call our Electronic Ordering Help
            Desk for assistance.
          </p>
        )}
        {this.state.showInternalError && (
          <p className="error">
            We have experienced an internal service error, please try again.
          </p>
        )}
        <form
          onSubmit={this.handleSubmit}
          action={this.state.url}
          method="post"
          id="loginForm"
          ref={this.formRef}
        >
          {scope === "diycustomers" ? (
            <div>
              <FloatingLabelTextField
                label="Email or Username"
                type="text"
                value={this.state.username}
                onChange={this.handleUsernameChange}
                name="username"
                validation={this.validateUsername}
                information={this.usernameInformation}
                onKeyPress={event => this.handleKeyPress("password", event)}
              />

              <div className="field-space" />
              <FloatingLabelTextField
                label="Password"
                type="password"
                value={this.state.password}
                onChange={this.handlePasswordChange}
                information={this.passwordInformation}
                name="password"
              />

              <div className="button-space" />
            </div>
          ) : (
            <div>
              <input
                type="text"
                name="username"
                className={this.state.usernameError ? `error` : null}
                placeholder="Username"
                autoCapitalize="none"
                value={this.state.username}
                onChange={this.handleUsernameChange}
              />
              {this.state.scope === "az_mobile_sso" ? (
                <div className="commercial-pwd-container">
                  <input
                    type={this.state.isShowPassword ? "text" : "password"}
                    name="password"
                    className={this.state.passwordError ? `error` : null}
                    placeholder="Password"
                    value={this.state.password}
                    onChange={this.handlePasswordChange}
                  />
                  <button
                    type="button"
                    aria-label={this.state.eyeIconAltStr}
                    onClick={this.handlePasswordToggle}
                  >
                    <img
                      className="commercial-hide-show-img"
                      src={
                        !this.state.isShowPassword ? closedEyeIcon : openEyeIcon
                      }
                      alt={this.state.eyeIconAltStr}
                    />
                  </button>
                </div>
              ) : (
                <input
                  type="password"
                  name="password"
                  className={this.state.passwordError ? `error` : null}
                  placeholder="Password"
                  value={this.state.password}
                  onChange={this.handlePasswordChange}
                />
              )}
            </div>
          )}
          <input type="hidden" name="scope" value={this.state.scope} />
          <input
            type="hidden"
            name="response_type"
            value={this.state.responseType}
          />
          <input type="hidden" name="client_id" value={this.state.clientId} />
          <input
            type="hidden"
            name="code_challenge"
            value={this.state.codeChallenge}
          />
          <input
            type="hidden"
            name="code_challenge_method"
            value={this.state.codeChallengeMethod}
          />
          <input type="hidden" name="state" value={this.state.state} />
          <input
            type="hidden"
            name="redirect_uri"
            value={this.state.redirectUri}
          />
          {scope === "diycustomers" ? (
            <div>
              <AZSecondaryButton
                type="submit"
                title="SIGN IN"
                handlePress={() => null}
              />
              <div className="button-space" />
            </div>
          ) : (
            <input type="submit" value="Login" />
          )}
        </form>
        {scope === "diycustomers" ? (
          <div className="forgot-password">
            <LinkText title="Forgot Password?" />
          </div>
        ) : (
          <p>
            Forgot Username or Password?
            <br />
            Please call{" "}
            {scope === "az_mobile_sso" ? (
              <a href="tel:1-800-435-7871">1-800-435-7871</a>
            ) : (
              <a href="tel:1-866-853-6459">1-866-853-6459</a>
            )}{" "}
            for assistance.
          </p>
        )}
      </div>
    );
  }
}

export default App;
